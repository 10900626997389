import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../config/supabaseConfig";
import * as XLSX from "xlsx";

type Props = {};

interface Appointment {
  id: string;
  created_at: string;
  name: string;
  age: number;
  address: string;
  mobile_no: number;
  disease_detail: string;
  disease_duration: string;
  book_time: string;
  book_day: string;
  followup: boolean;
  gender: "male" | "female" | "";
  prefer_time: "morning" | "noon" | "";
  prefer_date: string;
  branch: "dhrangadhra" | "ahmedabad" | "";
}

enum AppointmentTypeEnum {
  NEW = "NEW",
  SCHEDULED = "SCHEDULED",
  FINISHED = "FINISHED",
}

enum BranchEnum {
  dhrangadhra = "dhrangadhra",
  ahmedabad = "ahmedabad",
}

const Dashboard = (props: Props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const user = useSelector((state: RootState) => state.user.user);

  const [appointments, setAppointments] = useState<Appointment[] | any>([]);

  const [filteredAppointments, setFilteredAppointments] = useState<
    Appointment[] | any
  >([]);

  const [appointmentType, setAppointmentType] = useState(
    AppointmentTypeEnum.NEW
  );

  const [branch, setBranch] = useState(BranchEnum.ahmedabad);

  const fetchAppointments = async () => {
    const { data, error } = await supabase.from("appointments").select();
    console.log(error);
    setAppointments(data);
  };

  const checkAuth = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      navigate("/admin");
    }
  };

  useEffect(() => {
    checkAuth();

    fetchAppointments();
  }, []);

  useEffect(() => {
    let tempArray: any = appointments?.filter((value: any) => {
      // console.log(new Date(value.book_day) >= new Date());
      // console.log(new Date(value.book_day) < new Date());
      if (appointmentType === AppointmentTypeEnum.NEW) {
        return (
          value.book_day === null &&
          value.book_time === null &&
          value?.branch === branch
        );
      } else if (appointmentType === AppointmentTypeEnum.SCHEDULED) {
        return (
          new Date(value.book_day) >= new Date() &&
          value.book_day !== null &&
          value.book_time !== null &&
          value?.branch === branch
        );
      } else {
        return (
          new Date(value.book_day) < new Date() && value?.branch === branch
        );
      }
    });
    setFilteredAppointments(tempArray);
    console.log(filteredAppointments);
  }, [appointments, branch, appointmentType]);

  const onLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.success("Logout Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/admin");
    }
  };

  const updateBookingTime = async (appointment: Appointment) => {
    const { error } = await supabase
      .from("appointments")
      .update({
        book_day: appointment.book_day,
        book_time: appointment.book_time,
      })
      .eq("id", appointment.id);

    if (error) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      fetchAppointments();
      toast.success("Booking time updated Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const deleteAppointment = async (id: string) => {
    const { error } = await supabase.from("appointments").delete().eq("id", id);

    if (error) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      fetchAppointments();
      toast.success("Appointment deleted Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const downloadAsExcell = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(appointments);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "appointments.xlsx");
  };

  return (
    <div>
      <div className="flex justify-end">
        <button
          className="p-2  border-green-700 border-2 text-green-700 font-bold rounded-md m-4"
          onClick={downloadAsExcell}
        >
          download all appointments
        </button>
        <button
          className="p-2  border-green-700 border-2 text-green-700 font-bold rounded-md m-4"
          onClick={onLogout}
        >
          logout
        </button>
      </div>

      <div>
        <div className="flex justify-center my-4">
          <span
            className={
              "p-3 mx-2 rounded-lg cursor-pointer " +
              (branch === BranchEnum.ahmedabad
                ? "bg-green-700 text-white"
                : "bg-green-200 text-gray-900")
            }
            onClick={() => {
              setBranch(BranchEnum.ahmedabad);
            }}
          >
            Ahmedabad
          </span>
          <span
            className={
              "p-3 mx-2 rounded-lg cursor-pointer " +
              (branch === BranchEnum.dhrangadhra
                ? "bg-green-700 text-white"
                : "bg-green-200 text-gray-900")
            }
            onClick={() => {
              setBranch(BranchEnum.dhrangadhra);
            }}
          >
            Dhrangadhra
          </span>
        </div>
        <div className="flex justify-center my-4">
          <span
            className={
              "p-3 mx-2 rounded-lg cursor-pointer " +
              (appointmentType === AppointmentTypeEnum.NEW
                ? "bg-green-700 text-white"
                : "bg-green-200 text-gray-900")
            }
            onClick={() => {
              setAppointmentType(AppointmentTypeEnum.NEW);
            }}
          >
            New Appointments
          </span>
          <span
            className={
              "p-3 mx-2 rounded-lg cursor-pointer " +
              (appointmentType === AppointmentTypeEnum.SCHEDULED
                ? "bg-green-700 text-white"
                : "bg-green-200 text-gray-900")
            }
            onClick={() => {
              setAppointmentType(AppointmentTypeEnum.SCHEDULED);
            }}
          >
            Scheduled Appointments
          </span>
          <span
            className={
              "p-3 mx-2 rounded-lg cursor-pointer " +
              (appointmentType === AppointmentTypeEnum.FINISHED
                ? "bg-green-700 text-white"
                : "bg-green-200 text-gray-900")
            }
            onClick={() => {
              setAppointmentType(AppointmentTypeEnum.FINISHED);
            }}
          >
            Finished Appointments
          </span>
        </div>
      </div>

      <div className="p-4 w-full">
        {filteredAppointments &&
          filteredAppointments?.length > 0 &&
          filteredAppointments.map((appointment: Appointment) => (
            <div
              className={
                " p-4  rounded-e-xl rounded-b-xl mb-4 mt-16 relative " +
                (appointment.gender === "male" ? "bg-blue-200" : "bg-pink-200")
              }
              key={appointment?.id}
            >
              <div className="absolute h-10 top-[-40px] bg-yellow-200 rounded-t-lg left-0 p-3 flex justify-center items-center">
                <p className="font-semibold">
                  {appointment.followup ? "Followup Case" : "New Case"}
                </p>
              </div>

              <div className="grid grid-cols-3 gap-4">
                <p className="my-1 font-semibold">
                  {appointment?.name} ({appointment?.age})
                </p>
                <p className="my-1 font-semibold">{appointment?.mobile_no}</p>

                <p className="my-1 font-semibold">{appointment?.address}</p>
              </div>
              <hr className="my-4 h-1 bg-green-500" />
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="my-1 font-thin">Disease Detail:</p>
                  <p className="my-1 font-semibold">
                    {appointment?.disease_detail}
                  </p>
                </div>
                <div>
                  <p className="my-1 font-thin">Disease Duration:</p>
                  <p className="my-1 font-semibold">
                    {appointment?.disease_duration}
                  </p>
                </div>
              </div>
              <hr className="my-4 h-1 bg-green-500" />
              <div className="my-2">
                <input
                  disabled
                  className="mr-4 rounded-sm p-2"
                  type="date"
                  placeholder="booking date"
                  value={appointment?.prefer_date}
                />
                <input
                  disabled
                  className="mr-4 rounded-sm p-2"
                  type="text"
                  placeholder="booking time"
                  value={appointment.prefer_time}
                />
              </div>
              <div className="">
                <input
                  required
                  className="mr-4 rounded-sm p-2"
                  type="date"
                  placeholder="booking date"
                  value={appointment?.book_day}
                  onChange={(e) => {
                    appointment.book_day = e.target.value;
                  }}
                />
                <input
                  required
                  className="mr-4 rounded-sm p-2"
                  type="time"
                  placeholder="booking time"
                  value={appointment.book_time}
                  onChange={(e) => {
                    appointment.book_time = e.target.value;
                  }}
                />
                <button
                  className="p-2 bg-green-700 text-white rounded-md font-semibold"
                  onClick={() => updateBookingTime(appointment)}
                >
                  save booking time
                </button>
                {appointmentType === AppointmentTypeEnum.SCHEDULED && (
                  <a
                    className="p-2 ml-2 bg-green-700 text-white rounded-md font-semibold"
                    href={
                      "https://wa.me/91" +
                      appointment.mobile_no +
                      "?text=Hi " +
                      appointment.name +
                      ", Your appointment at Shreeji Homeo Clinic " +
                      appointment.branch +
                      " is sheduled at " +
                      appointment.book_day +
                      " " +
                      new Date(
                        "1970-01-01T" + appointment.book_time + "Z"
                      ).toLocaleDateString("en-US", {
                        timeZone: "UTC",
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      }) +
                      "."
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    send message
                  </a>
                )}
              </div>
              <div className="my-4">
                <button
                  className="p-2 rounded-md border-red-600 border-2 text-red-600 font-bold"
                  onClick={() => {
                    deleteAppointment(appointment?.id);
                  }}
                >
                  Delete appointment
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
