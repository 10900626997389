import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserReducer from "./slice/user";

export const rootReducer = combineReducers({
  user: UserReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof rootReducer>;
