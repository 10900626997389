import { Route, Routes } from "react-router-dom";
import "./App.css";
import Appointment from "./pages/Appointment";
import Admin from "./pages/Admin";
import { ToastContainer } from "react-toastify";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Appointment />} />
        <Route path="admin" element={<Login />} />
        <Route path="dashboard" element={<Dashboard />} />

        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
