import React, { useState } from "react";
import { toast } from "react-toastify";
import { supabase } from "../config/supabaseConfig";
import "react-toastify/dist/ReactToastify.css";

function Appointment() {
  const [appointmentForm, setAppointmentForm] = useState<{
    name: string;
    age: string;
    address: string;
    disease_detail: string;
    disease_duration: string;
    mobile: string;
    isFollowUp: boolean;
    gender: "male" | "female" | "";
    prefer_time: "morning" | "noon" | "";
    prefer_date: string;
    branch: "dhrangadhra" | "ahmedabad" | "";
  }>({
    name: "",
    age: "",
    address: "",
    mobile: "",
    disease_detail: "",
    disease_duration: "",
    isFollowUp: false,
    gender: "",
    prefer_time: "",
    prefer_date: "",
    branch: "",
  });

  const bookAppointment = async (e: any) => {
    e.preventDefault();
    const { error } = await supabase.from("appointments").insert({
      name: appointmentForm.name,
      age: appointmentForm.age,
      address: appointmentForm.address,
      mobile_no: appointmentForm.mobile,
      disease_detail: appointmentForm.disease_detail,
      disease_duration: appointmentForm.disease_duration,
      followup: appointmentForm.isFollowUp,
      gender: appointmentForm.gender === "male" ? "male" : "female",
      prefer_date: appointmentForm.prefer_date,
      prefer_time: appointmentForm.prefer_time,
      branch: appointmentForm.branch,
    });
    console.log(error);
    if (error) {
      toast.error("something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setAppointmentForm({
        name: "",
        age: "",
        address: "",
        mobile: "",
        disease_detail: "",
        disease_duration: "",
        isFollowUp: false,
        gender: "",
        prefer_time: "",
        prefer_date: "",
        branch: "",
      });
      toast.success("Appointment booked successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <div className="p-4 bg-white">
      <h1 className="text-4xl text-green-900 mb-4 text-center">
        Shreeji Homeo Clinic
      </h1>
      <div className="text-center mb-2">
        <a
          className="text-base text-green-900  text-center"
          href="https://g.co/kgs/RnR2Aw"
        >
          FF 30, shukan Pletinam, Vande Mataram Cross road, Gota - 382481
        </a>
      </div>

      <h2 className="text-base text-green-900 mb-2 text-center">
        નીલકંઠ ટ્રેક્ટર, ટાફે શોરૂમ, હળવદ રોડ, ધ્રાંગધ્રા
      </h2>
      <div className="text-center mb-4">
        <a
          className="text-base text-green-900 mb-2 text-center"
          href="tel:+917016787090"
        >
          Contact No: 70167 87090
        </a>
      </div>
      <div className="text-center mb-10">
        <a
          className="font-semibold border-2 border-green-700 text-green-900 rounded-md p-2"
          href="https://maps.app.goo.gl/9ysiDZ7Y2wmMrH657"
          target="_blank"
          rel="noreferrer"
        >
          visit our clinic
        </a>
      </div>

      <div className="flex justify-center items-center">
        <form onSubmit={bookAppointment} className="w-full sm:w-1/2">
          <div className="mb-6">
            <label
              htmlFor=""
              className=" mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Branch (શાખા)
            </label>{" "}
            <br />
            <select
              required
              name="cars"
              id="cars"
              value={appointmentForm.branch}
              className="bg-gray-50 border pr-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return {
                    ...value,
                    branch:
                      e.target.value === "ahmedabad"
                        ? "ahmedabad"
                        : "dhrangadhra",
                  };
                });
              }}
            >
              <option value={""} disabled>
                choose Branch
              </option>

              <option value="ahmedabad">Gota-Ahmedabad (ગોતા - અમદાબાદ)</option>
              <option value="dhrangadhra">Dhrangadhra (ધ્રાંગધ્રા)</option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Name (નામ)
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your Name"
              required
              value={appointmentForm.name}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, name: e.target.value };
                });
              }}
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Age (ઉંમર)
            </label>
            <input
              type="number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your Age"
              required
              value={appointmentForm.age}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, age: e.target.value };
                });
              }}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor=""
              className=" mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Gender (જેન્ડર)
            </label>{" "}
            <br />
            <select
              required
              name="cars"
              id="cars"
              value={appointmentForm.gender}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return {
                    ...value,
                    gender: e.target.value === "male" ? "male" : "female",
                  };
                });
              }}
            >
              <option value={""} disabled>
                choose gender
              </option>

              <option value="male">male (પુરુષ)</option>
              <option value="female">female (મહીલા)</option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Address (સરનામું)
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your address"
              required
              value={appointmentForm.address}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, address: e.target.value };
                });
              }}
            />
          </div>{" "}
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Mobile Number (ફોન નંબર)
            </label>
            <input
              type="number"
              max={9999999999}
              min={6000000000}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Your Contact Number"
              required
              value={appointmentForm.mobile}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, mobile: e.target.value };
                });
              }}
            />
          </div>{" "}
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Disease Detail (રોગની વીગત)
            </label>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Disease Detail"
              required
              value={appointmentForm.disease_detail}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, disease_detail: e.target.value };
                });
              }}
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Disease Duration (કેટલા સમય થી રોગ છે ?)
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Duration of Disease"
              required
              value={appointmentForm.disease_duration}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, disease_duration: e.target.value };
                });
              }}
            />
          </div>
          <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Prefered Date (તારીખ પસંદ કરો)
            </label>
            <input
              type="date"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Duration of Disease"
              required
              value={appointmentForm.prefer_date}
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, prefer_date: e.target.value };
                });
              }}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor=""
              className=" mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Prefered Time (સમયગાળો પસંદ કરો)
            </label>{" "}
            <br />
            <select
              required
              name="cars"
              id="cars"
              value={appointmentForm.prefer_time}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return {
                    ...value,
                    prefer_time:
                      e.target.value === "morning" ? "morning" : "noon",
                  };
                });
              }}
            >
              <option value={""} disabled>
                Choose prefered time
              </option>

              <option value="morning">
                Morning - સવારે (10:00 am to 12:00 am)
              </option>
              <option value="noon">
                Afternoon - સાંજે (2:00 pm to 4:00 pm)
              </option>
            </select>
          </div>
          <div className="mb-6">
            <input
              type="radio"
              name="followup"
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, isFollowUp: false };
                });
              }}
            />
            <label
              htmlFor=""
              className="ml-2 mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              New Case (નવો કેસ)
            </label>{" "}
            <br />
            <input
              type="radio"
              name="followup"
              onChange={(e) => {
                setAppointmentForm((value) => {
                  return { ...value, isFollowUp: true };
                });
              }}
            />
            <label
              htmlFor=""
              className="ml-2 mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Follow Up Case (જૂનો કેસ)
            </label>
          </div>
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="submit"
          >
            Book Appointment (અપોઇન્ટમેન્ટ બૂક કરો)
          </button>
        </form>
      </div>
    </div>
  );
}

export default Appointment;
